.card-table {
    margin-bottom: 1rem;
    border: 1px solid #c3c3c3;
    border-radius: 4px;
    background-color: #fff;
    .-cardrow {
        margin-right: 0;
        margin-left: 0;
        &:nth-of-type(n + 2) {
            border-top: 1px solid #ccc;
        }
        .-column {
            > div {
                margin-bottom: 0.5rem;
                font-size: 0.77778rem;
                word-break: break-all;
            }
        }
    }
}
