@mixin gradient($c1, $c2) {
    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
        width: calc(100% + 150px);
        height: 100%;
        z-index: -1;
        transition: all 0.3s;
        background-image: linear-gradient(45deg, $c1, $c2);
    }
    &:hover::after {
        left: -150px;
    }
}

.dashboard-box {
    position: relative;
    display: block;
    width: 350px;
    color: #fff;
    // border-radius: 5px;
    margin: 15px;
    cursor: pointer;
    overflow: hidden;
    > .-top {
        display: block;
        padding: 15px;
        > .-left,
        > .-right {
            display: inline-block;
            padding-right: 15px;
            padding-top: 15px;
            padding-bottom: 15px;
            > .MuiSvgIcon-root {
                font-size: 3rem;
            }
        }
    }
    > .-bottom {
        font-size: 1.8rem;
        text-align: right;
        padding: 5px 15px;
        border-top: 1px solid #fff;
    }
    &.-red {
        @include gradient(#f86624, #ea3546);
    }
    &.-green {
        @include gradient(#c2e400, #2cc20a);
    }
    &.-sky {
        @include gradient(#2decb5, #0412ff);
    }
    &.-pink {
        @include gradient(#f700ef, #f82424);
    }

    &.-default {
        @include gradient(#1e4382, #172b4d);
    }
}

.admin-custom-tab {
    &.nav-tabs .nav-link:focus,
    &.nav-tabs .nav-link:hover {
        outline: unset;
    }
    + .tab-content > .tab-pane {
        padding: 24px 0;
        background-color: #fff;
    }
}

.admin-ea-custom-popup {
    width: fit-content;
    max-width: 1000px;
    > .modal-content {
        width: fit-content;
    }
}
.admin-custom-table {
    // overflow: visible;
    // overflow-x: auto;
}
.admin-custom-table > .react-bs-table {
    > .react-bs-container-header {
        background-color: #ebebeb;
        th {
            font-size: 0.889rem;
            color: #707070;
            border: unset;
        }
    }
    > .react-bs-container-body > .table > tbody > tr > td {
        font-size: 90%;
        vertical-align: middle;
        &:focus {
            outline: unset;
        }
        img {
            object-position: left;
        }
    }
}
