$primary-color: #172b4d;
$default-color: #8898aa;

//
$body-background: #f1f3f9;

$navbar-background: transparent;

$sidebar-background: #efefef;
$sidebar-link-active: $primary-color;

//
$panel-background: #ffffff;
$panel-title-color: $primary-color;

//
$white-color: #ffffff;
$black-color: #000000;
$orange-color: #f24a00;
$red-color: #9f0202;
$blue-color: #044177;
$sky-color: #08a6e4;
$gray-color: #8898aa;
$green-color: #009688;
$light-green: #5ec568;
$light-white: #efefef;
$light-gray: #777;
$yellow-color: #f7f42a;
$home-panel-color: #1b3249;
$dark-yellow: #e0a706e6;
