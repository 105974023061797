@import "./variable.scss";
.about {
    > .header {
        background-image: url("../images/bg2.png");
    }
    .regisinfo:hover {
        cursor: pointer;
        transition: 1s;
        transform: rotate(0deg) scale(1.1);
    }
    > .title {
        color: $orange-color;
    }
}
.title {
    color: $orange-color;
}
.aboutus-bg {
    background-image: url("../images/aboutus.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 50px 0px;
    width: 100%;
    min-height: 480px;
    background-position: center;
    color: #fff;
}
.aboutus-text {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
}
