@import "~styles/variable.scss";

.navbar {
	position: absolute !important;
	top: 0;
	display: flex;
	align-items: center;
	background-color: $navbar-background;
	z-index: 99;
}
.noti-alert {
	width: 20px;
	height: 20px;
	background-color: #bd2907;
	border-radius: 50%;
	margin-left: -15px;
	margin-top: -16px;
	color: $white-color;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.65rem;
}
.noti-alert-list {
	width: 11px;
	height: 11px;
	background-color: #bd2907;
	border-radius: 50%;
	color: $white-color;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 0.65rem;
}
// .noti-read{

// }

.user_container,
.admin_container {
	position: relative;
	margin-left: 250px;
}

.admin_container {
	padding: 150px 60px 60px 60px;
}

.user_menu {
	display: none;
}

.navbar.user {
	left: 0;
	right: 0;
	height: 100px;
	justify-content: space-between;
	background-color: $blue-color;

	> .-left {
		display: inline-flex;
		padding-left: 25px;

		> .-icon {
			display: none;
		}
	}
	> .-right {
		display: flex;
		align-items: center;
		color: $white-color;
		min-width: 50vh;
		> div {
			padding: 10px;
		}
		> .-noti {
			position: relative;
			> button {
				&:focus {
					outline: unset;
				}
				.MuiSvgIcon-root {
					color: #fff;
					font-size: 2.5rem;
				}
			}
			> .-list {
				position: absolute;
				top: calc(100% - 0.25rem);
				left: calc(100% - 13rem);
				right: auto;
				color: #000;
				min-width: 220px;
				max-height: 200px;
				list-style: none;
				margin-block-start: 0;
				padding-inline-start: 0;
				border-radius: 2px;
				overflow-y: auto;
				background-color: #fff;
				box-shadow: 0 0 10px #00000050;
				&::-webkit-scrollbar {
					width: 5px;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 5px;
					background-color: darkgrey;
					outline: 1px solid slategrey;
				}
				> li {
					padding: 15px;
					&:nth-of-type(n + 1) {
						border-top: 1px solid #cccccc;
					}
					> .-title {
						color: $blue-color;
						font-weight: 600;
					}
					> .-detail {
						font-size: 80%;
					}
				}
			}
		}
		> .-help {
			font-size: 1.5rem;
			// margin-right: 20px;
		}
		> .-user {
			display: flex;
			align-items: center;
			> .-pic {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin: 0 10px;
				overflow: hidden;
				cursor: pointer;
				> img {
					object-fit: cover;
				}
			}
		}
		> .menu_icon {
			display: none;
		}
	}
}
.hover-noti:hover {
	background-color: #cccc !important;
	cursor: pointer;
}
.navbar.admin {
	left: 0;
	right: 0;
	height: 100px;
	justify-content: space-between;
	background-color: $blue-color;

	> .-left {
		display: inline-flex;
		padding-left: 25px;

		> .-icon {
			display: none;
		}
	}
	> .-right {
		display: flex;
		align-items: center;
		color: $white-color;

		> div {
			padding: 10px;
		}
		> .-noti {
			position: relative;
			> button {
				&:focus {
					outline: unset;
				}
				.MuiSvgIcon-root {
					color: #fff;
					font-size: 2.5rem;
				}
			}
			> .-list {
				position: absolute;
				top: calc(100% - 0.5rem);
				left: calc(100% - 9rem);
				right: auto;
				color: #000;
				min-width: 150px;
				max-height: 200px;
				list-style: none;
				margin-block-start: 0;
				padding-inline-start: 0;
				border-radius: 2px;
				overflow-y: auto;
				background-color: #fff;
				box-shadow: 0 0 10px #00000050;
				&::-webkit-scrollbar {
					width: 5px;
				}
				&::-webkit-scrollbar-thumb {
					border-radius: 5px;
					background-color: darkgrey;
					outline: 1px solid slategrey;
				}
				> li {
					padding: 15px;
					&:nth-of-type(n + 1) {
						border-top: 1px solid #cccccc;
					}
					> .-title {
						color: $blue-color;
						font-weight: 600;
					}
					> .-detail {
						font-size: 80%;
					}
				}
			}
		}
		> .-help {
			font-size: 1.5rem;
			// margin-right: 20px;
		}
		> .-user {
			display: flex;
			align-items: center;
			> .-pic {
				width: 50px;
				height: 50px;
				border-radius: 50%;
				margin: 0 10px;
				overflow: hidden;
				cursor: pointer;
				> img {
					object-fit: cover;
				}
			}
		}
		> .menu_icon {
			display: none;
		}
	}
}
.-time {
	font-size: 65%;
	color: #777777;
	width: 100%;
}

.navbar.admin {
	background-image: linear-gradient(to right, $primary-color, $black-color);
}
.dropleft,
.dropright,
.dropup {
	position: relative;
	border-right: 1px solid #ccc;
}

.navbar.none_user {
	right: 5%;
	left: 5%;
	width: 90%;
	height: auto;

	justify-content: space-between;
	> .-left {
		> .logo {
			width: 120px;
			height: 100px;
		}
		> .menu_icon {
			display: none;
		}
	}
	> .dropdown {
		color: "transparent";
	}
	> .-right {
		display: flex;
		justify-content: space-between;
		width: calc(100% - 120px);
		> .menu {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: calc(100% - 18vw);
			list-style: none;
			margin-bottom: 0;
			> .link > a {
				font-size: 1.4rem;
				color: $white-color;
				font-weight: 700;
				text-decoration: unset;
				&:hover,
				&.active {
					color: $orange-color;
				}
			}
		}
		.btn-orange {
			font-size: 1rem;
			min-width: 12vw;
			padding-top: 0.475rem;
			padding-bottom: 0.475rem;
		}
	}
	.dropdown {
		font-size: 1.4rem;
		color: #ffffff;
		font-weight: 600;
		text-decoration: unset;
	}

	.dropdown-content {
		> a {
			text-decoration: unset;
			:hover {
				background-color: $blue-color;
			}
		}
	}
}

@media screen and (max-width: 1280px) {
	.navbar.none_user {
		> .-right {
			> .menu {
				> .link > a {
					font-size: 1.1rem;
				}
			}
		}
	}
}

@media screen and (max-width: 1080px) {
	.navbar.none_user {
		position: relative !important;
		right: 0;
		left: 0;
		width: 100%;
		height: auto;
		padding: 0;
		> .-left {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			padding: 15px 5%;
			background-image: linear-gradient(to right, #052b46, #055665);
			> .menu_icon {
				display: block;
				height: fit-content;
				color: $white-color;
				background-color: $orange-color;
				border: 2px solid transparent;
				&:focus {
					border: 2px solid $white-color;
					outline: unset;
				}
				> .MuiSvgIcon-root {
					font-size: 3rem;
				}
			}
			> .logo {
				width: 120px;
				height: 60px;
			}
			> .menu_icon > .MuiSvgIcon-root {
				font-size: 2rem;
			}
		}
		> .-right {
			display: block;
			width: 100%;
			height: fit-content;
			padding-top: 1rem;
			padding-bottom: 1rem;
			transition: 0.5s all;
			overflow: hidden;
			&.show {
				height: 588px;
			}
			&.hidden {
				padding: 0;
				height: 0;
			}
			> .menu {
				display: block;
				> .link {
					display: flex;
					> a {
						color: $primary-color;
						font-size: 1.4rem;
						padding-top: 1rem;
						padding-bottom: 1rem;
					}
				}
			}
			.btn-orange {
				font-size: 1.2rem;
				margin-top: 30px;
				margin-left: 40px;
				margin-bottom: 10px;
				width: 100%;
				max-width: 250px;
				padding-top: 0.975rem;
				padding-bottom: 0.975rem;
			}
		}
		.dropdown {
			padding: 1rem 0;
			color: unset;
		
		}
		
	}
	.user_container,
	.admin_container {
		position: relative;
		margin-left: 0;
	}
	.user_menu {
		display: block;
		list-style: none;
		padding-top: 112px;
		transition: all 0.5s;
		overflow: hidden;
		z-index: -1;
		> .link {
			height: 40px;
			> a {
				height: 100%;
				font-weight: 700;
				color: $primary-color;
				> .MuiSvgIcon-root {
					margin-right: 10px;
				}
			}
		}
		&.show {
			height: auto;
		}
		&.hidden {
			height: 0;
			padding-top: 86px;
		}
	}
	.navbar.user,
	.navbar.admin {
		flex-wrap: unset;
		> .-left {
			> .-icon {
				display: block;
				width: 80px;
			}
			> .-back {
				display: none;
			}
		}
		> .-right {
			padding-right: 20px;
			> .menu_icon {
				display: block;
				height: fit-content;
				color: $white-color;
				background-color: $orange-color;
				border: 2px solid transparent;
				&:focus {
					border: 2px solid $white-color;
					outline: unset;
				}
				> .MuiSvgIcon-root {
					font-size: 2rem;
				}
			}
		}
	}
}

@media screen and (max-width: 580px) {
	.navbar.user,
	.navbar.admin {
		> .-left {
			padding: 0;
			> .-icon {
				display: none;
			}
		}
		> .-right {
			padding: 0;
			min-width: unset;
			> .-help {
				margin-right: 5px;
			}

			> .-user > .-name {
				display: none;
			}
			> .-noti > .-list {
				min-width: 20vh;
			}
		}
	}
}

.dropdown {
	&-toggle {
		&::after {
			// display: inline-block;
			margin-left: 0.255em;
			background-color: $white-color;
			vertical-align: 0.255em;
			content: "";
			border-top: 0.3em solid;
			border-right: 0.3em solid transparent;
			border-bottom: 0;
			border-left: 0.3em solid transparent;
			color: $white-color;
			font-weight: 700;
			background-color: $primary-color;
		}
	}
	&-content {
		display: none;
		position: absolute;
		width: fit-content;
		font-size: 0.8rem;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		z-index: 1;
		a {
			padding: 0.25rem 1.5rem;
			// color: $black-color;
			text-align: left;
			&:hover {
				color: $white-color;
				background-color: $red-color;
			}
		}
	}
	&:hover,
	&.active,
	&:focus-within {
		.dropdown-content {
			display: grid;
			color: $orange-color;
			background-color: $white-color;
			justify-content: left;
		}
	}
}
