@import "./variable.scss";
.panel-custom {
	height: 241px;
	display: flex;
	align-items: center;
	max-width: 58%;
	margin: 2 5px;
	// flex: wrap;
	width: 100%;
}
.broker-name-responsive {
	.broker-name-1 {
		&:nth-of-type(n + 2) {
			&::before {
				content: ",";
				margin-right: 5px;
			}
		}
	}
}
.ea_detail_a {
	color: $orange-color !important;
	text-decoration: underline !important;
	cursor: pointer;
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	justify-content: center;
}
.copy_trade_list_detail {
	color: $orange-color !important;
	text-decoration: underline !important;
	cursor: pointer;
	display: flex;
}
.copy_trade_list_detail_mob{
	color: $orange-color !important;
	text-decoration: underline !important;
	cursor: pointer;

}
.ea_detail {
	text-decoration: none;
	text-overflow: ellipsis;
	display: block;
	overflow: hidden;
	white-space: nowrap;
	width: 700px;
}
.img-animation {
	:hover {
		transform: rotate(0deg) scale(1.05);
		transition: 1s;
		cursor: pointer;
	}
}
.panel-yourbalance {
	.-top {
		margin-bottom: 200px;
	}
	.-bottom {
		position: absolute;
		bottom: 0;
		right: 30px;
		left: 30px;
		padding: 30px 0;
		border-top: 1px solid #ccc;
	}
}
.panel-video {
	width: 612px;
	height: 344px;

	top: 0px;
}
.panel-user {
	height: 241px;
	max-width: 30%;
	margin: 2 5px;
	width: 100%;
	// flex-wra: wrap;
}
.panel-iflam {
	height: 241px;
	display: flex;
	align-items: center;
	max-width: 22%;
	margin: 2 5px;
	width: 100%;
}
.panel-search {
	background-color: $panel-background;
	border-radius: 2px;
	z-index: 2;
	padding: 3px;
	box-shadow: 0 0 10px 0 #0000001a;
	margin: 10px 0;
}
.btn-black-responsive {
	width: 309px;
	height: auto;
}
.ea-img-resposive {
	width: 100px;
}
@media screen and (max-width: 1280px) {
	.panel-custom {
		max-width: 40%;
	}
	.panel-user {
		max-width: 16%;
	}
	.panel-iflam {
		max-width: 22%;
	}
	.ea-img-resposive {
		width: 60px;
	}
	.text-gray {
		font-size: 14px;
	}
	.ea-img-resposive {
		width: 100px;
	}
}

@media screen and (max-width: 1080px) {
	.btn-responsive {
		font-size: 12px;
		width: 100%;
		margin: 28px 0;
	}
	.copytrade-broker {
		font-size: 12px;
	}
}
@media screen and (max-width: 980px) {
	.dashboard-container > .content {
		padding: 20px 20px;
	}
	.panel-custom {
		max-width: 40%;
	}
	.panel-user {
		max-width: 16%;
	}
	.panel-iflam {
		max-width: 22%;
	}
}

@media screen and (max-width: 780px) {
	.btn-black-responsive {
		width: 100%;
		// margin: 5px 5px;
		// font-size: 8px;
	}
	.panel-custom {
		max-width: 100%;
	}
	.panel-user {
		max-width: 100%;
	}
	.panel-iflam {
		max-width: 100%;
	}
	.panel-user-respnsive {
		display: flex;
		justify-content: center;
	}
	// .text-gray {
	// 	font-size: 1.5rem;
	// }
}
@media screen and (max-width: 480px) {
	.btn-black-responsive {
		width: 100px;
		// font-size: 8px;
	}
	.broker-name-responsive {
		display: block;
	}
	.ea_detail {
		width: unset;
	}
}
