@import "~styles/variable.scss";

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: block;
    width: 250px;
    color: $white-color;
    background-color: $sidebar-background;
    > .logo {
        display: block;
        width: 200px;
        height: 100px;
        margin: 25px auto;
        > img {
            object-fit: cover;
        }
    }
    > .menu {
        display: block;
        width: 100%;
        max-height: calc(100% - 160px);
        list-style-type: none;
        margin: 10px 0;
        padding: 0;
        overflow-y: auto;
        > .link {
            display: block;
            > a {
                display: flex;
                width: 100%;
                height: 40px;
                align-items: center;
                color: $primary-color;
                font-size: 1.2rem;
                font-weight: 500;
                padding: 30px;
                cursor: pointer;
                > .MuiSvgIcon-root {
                    margin-right: 1rem;
                }
            }
            // > .active {
            //     color: $white-color;
            //     background-color: $sidebar-link-active;
            // }
            &:hover {
                // background-color: $sidebar-link-active;
                > a {
                    // color: $white-color;
                    text-decoration: unset;
                }
            }
        }
    }
}
.sidebar > .menu > .link {
    display: block;
    a:hover {
        background-color: $orange-color;
        color: $white-color;
    }
}

@media screen and (max-width: 1080px) {
    .sidebar {
        display: none;
    }
}
