//color
@import "./variable.scss";

.btn {
  &-gain-range {
    padding: 0.375rem 1.5rem;
    font-weight: 300;
    background-color: $light-white;
    border-color: $light-gray;
    color: $black-color;

    :hover {
      background-color: unset;
    }

    &.active {
      background-color: $light-gray !important;
      border-color: $black-color !important;
    }
  }
}

.copytrade-brokersupport-text {
  font-size: 16px !important;
  display: flex;
  justify-content: flex-end;
}
.copytrad-area {
  display: block;
}
.copytrade-broker {
  font-size: 0.9rem;
  color: $light-gray;
  height: 50px;
  max-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  h5 {
    font-weight: 600;
  }
}
.copytrade-broker-grap {
  font-size: 0.9rem;
  color: $light-gray;
  overflow: hidden;
  text-overflow: ellipsis;
  h5 {
    font-weight: 600;
  }
}
.copytrade-text {
  font-size: 0.9rem;
  color: $light-gray;

  h5 {
    font-weight: 600;
  }
}
.copytrade-broker-text {
  font-size: 0.9rem;
  color: $light-gray;

  overflow: hidden;
  text-overflow: ellipsis;
}
.copytrade-112 {
  font-size: 14px;
  color: $green-color;
}
.mt-10 {
  margin-top: 10px;
}
.risk-panel {
  width: 100%;
  padding: 1rem;
  margin: 0;
}

.header-green-text {
  color: $green-color;
  text-decoration: underline;
  font-size: 0.9rem;
  cursor: pointer;
}

.detail-contain {
  border-top: 1px solid #ccc;
}

.detail-panel {
  //   width: 20%;
  height: 70px;
  border-right: 1px solid #ccc;
  padding: 10px;
}
.detail-panel-1 {
  //   width: 20%;
  height: 70px;
  border-left: 1px solid #ccc;
  padding: 10px;
}
// .detail-panel::before {
// 	border-right: 1px solid #ccc;
// 	content: '';
// 	position: relative;
// 	padding: 20px;
// }

.gain-datepicker {
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
}

@media screen and (max-width: 1580px) {
  .broker-img {
    width: unset;
  }
}

@media screen and (max-width: 768px) {
  .detail-contain {
    border-top: 0;
  }
  .detail-panel {
    border-top: 1px solid #ccc;
    border-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .navbar.user > .-right > .-user > .-pic {
    display: none;
  }
  .copytrade-112 {
    font-size: 8px;
  }
  .copytrade-broker {
    font-size: 8px;
  }
  .col-6 {
    padding: unset;
  }
  .btn-green-responsive {
    font-size: 10px;
  }
  .header-text {
    font-size: 1rem;
  }
  .detail-contain {
    border-top: 0;
  }
  .detail-panel {
    border-top: 1px solid #ccc;
    border-right: 0;
  }

  .select-range-time {
    display: grid;
  }
  .copytrade-brokersupport-text {
    .MuiRating-root {
      font-size: unset;
    }
  }
}
