@import "../../styles/variable.scss";

.footer {
    padding-top: 5%;
    background-color: $primary-color;
    color: $white-color;
    min-height: 50vh;
    padding: 15px;
    > .h4 {
        font-weight: 500;
        margin-top: 20px;
    }

    > .row {
        margin: 0;
    }
    > div {
        margin: 10px 0;
    }
    .left {
        padding-left: 5%;
        padding-bottom: 5%;
        > img {
            height: 200px;
        }
    }
    a {
        font-size: 0.9999rem;
        margin-top: 50px !important;
        color: #fff !important;
        text-decoration: none !important;
        background-color: transparent;
        cursor: pointer;
    }
    .footer a {
        font-size: 0.9999rem;
        margin-top: 50px !important;
    }

    .terms {
        border-right: 1px solid #ccc;
        margin: 10px;
        padding: 0 5px;
    }
    .pavicy {
        margin: 10px 0;
        padding: 0 5px;
    }
    .contact-media {
        display: flex;
        justify-content: center;
    }
    .right {
        display: flex;
        padding-right: 5%;
        height: fit-content;
        > .menu {
            list-style: none;
            border-right: 2px solid #f24a00;
            padding-right: 50px;
            > li > a {
                color: #fff;
                line-height: 2;
            }
        }
        > .contact_list {
            display: flex;
            flex-direction: column;
            list-style: none;
            > li {
                &:nth-child(n + 2) {
                    margin-top: auto;
                }
                > a,
                > .call {
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-size: small;
                    > .img {
                        width: 50px;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    .footer-down {
        display: flex;
        > .-left {
            width: 50%;
            margin: 10px 5px;
            display: flex;
            img {
                width: 70px;
            }
            > a {
                color: #fff;
            }
        }
        > .-right {
            width: 50%;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}
.facebook {
    width: 30px;
    margin: 10px 5px;
    > a {
        color: #fff;
    }
}
.hr {
    border-top: 1px solid #ccc;
    margin-top: 100px;
}

@media screen and (max-width: 1200px) {
    .footer {
        .right {
            > .menu {
                display: none;
            }
            > .contact_list {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                max-width: 300px;
                height: 200px;
                padding-inline-start: 0;
                > li {
                    margin-top: auto;
                    margin-bottom: auto;
                    > a,
                    > .call {
                        > .img {
                            width: 100%;
                        }
                        > .text {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
