//color
@import "./variable.scss";
.dashboard-user {
	.-table {
		> .-web {
			@media screen and (max-width: 780px) {
				display: none;
			}
		}
		> .-mobile {
			display: none;
			@media screen and (max-width: 780px) {
				display: block;
			}
		}
	}
}
.invite-detail {
	// display: flex;
	font-size: 0.777776rem;
}
.dash-board-wallet {
	display: flex;
	justify-content: flex-end;
}
.dash-board-profile {
	display: flex;
	align-items: center;
	margin: 0 10px;
}
.dash-board-information {
	padding: 10px;
	margin: 20px 0;
}
.dash-board-img {
	width: 104.8px;
	// border-radius: 100px;
}
.btn {
	&-add-green {
		display: flex;
		border: unset;
		color: $green-color;
		align-items: center;

		&:hover {
			color: $green-color;
		}
	}
	&-add-sky {
		display: flex;
		border: unset;
		color: $sky-color;
		align-items: center;

		&:hover {
			color: $sky-color;
		}
	}
	&-add-yellow {
		display: flex;
		border: unset;
		color: $yellow-color;
		align-items: center;

		&:hover {
			color: $yellow-color;
		}
	}
}
.wd-bl {
	border-top: 1px solid #ccc;
	padding: 15px 15px;
	bottom: 0;
	top: 250px;
}
.header-panel {
	background-color: $blue-color;
	height: 70px;
	border-radius: 10px 10px 0 0;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;

	> div {
		color: $white-color;
	}
}
.user-custom-table > .react-bs-table {
	> .react-bs-container-header {
		background-color: $orange-color;
		th {
			font-size: 0.889rem;
			color: $white-color;
			border: unset;
		}
	}
	> .react-bs-container-body > .table > tbody > tr > td {
		font-size: 90%;
		vertical-align: middle;
		&:focus {
			outline: unset;
		}
		img {
			object-position: left;
		}
	}
}
.header-panel-table {
	background-color: $blue-color;
	height: 70px;
	border-radius: 10px 10px 0 0;
	margin-bottom: 30px;
	display: flex;
	align-items: center;
	padding: 10px;

	> div {
		color: $white-color;
		// font-size: 0.8888rem;
	}
}
.each-slide {
	div {
		display: flex;
		align-items: center;
		justify-content: space-around;
		background-size: cover;
		height: 300px;
	}
	.video-item {
		width: 317px;
		border-radius: 10px;
		padding: 10px;
		background-color: $white-color;
		box-shadow: 1px 1px 3px $light-gray;
		margin: 10px;
	}
	.slick-.dots li button:before,
	.slick-next:before,
	.slick-prev:before {
		color: $black-color !important;
	}
}

.btn-dropdown {
	.btn-primary {
		color: #fff;
		background-color: unset !important;
		border: unset !important;
	}
}
.content > .row-responsive {
	margin-left: -30px;
	margin-right: -30px;
	display: flex;
	justify-content: center;
	padding: 0 30px;
}
.myprofile-responsive {
	margin-right: 40px;
}
.yourbalance-responsive {
	margin-left: 40px;
}
.modaltable {
	.table td,
	.table th {
		border-top: unset !important;
	}
	.table thead th {
		border-bottom: unset !important;
	}
}
.openacc {
	color: red;
	margin: 5px 30px;
	font-size: 14px;
}
.dropdown-item:focus,
.dropdown-item:hover {
	color: #fff;
	text-decoration: none;
	background-color: #0056b3;
}
.status {
	&.padding {
		border-radius: 50%;
		width: 10px;
		height: 10px;
		background-color: $sky-color;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}
	&.approve {
		border-radius: 50%;
		width: 10px;
		height: 10px;
		background-color: $light-green;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}
	&.reject {
		border-radius: 50%;
		width: 10px;
		height: 10px;
		background-color: $red-color;
		margin-top: 0.5rem;
		margin-right: 0.5rem;
	}
}
.content.edit_profile {
	&.edit input {
		border-color: transparent;
		background-color: #fff;
	}
	&.save > .-profile > .custom-file-upload2::after {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		content: "เลือกรูป";
		display: block;
		width: 100%;
		height: 50px;
		font-size: 80%;
		text-align: center;
		color: $white-color;
		padding-top: 10px;
		cursor: pointer;
		text-decoration: underline;
		background-color: #00000090;
	}
	> .-profile {
		display: flex;
		justify-content: center;

		input[type="file"] {
			display: none;
		}
		> .custom-file-upload2 {
			position: relative;
			width: 150px;
			height: 150px;
			border: 1px solid $gray-color;
			border-radius: 50%;
			overflow: hidden;
			> img {
				object-fit: cover;
			}
		}
	}
	> .form-group {
		display: flex;
		align-items: center;

		> .form-label {
			white-space: nowrap;
			margin-right: 1rem;
			margin-bottom: 0;
		}
		> .af_link {
			display: flex;
			align-items: center;
			width: 100%;
			font-size: 80%;
			overflow: hidden;
			> span {
				display: block;
				width: calc(100% - 80px);
				white-space: nowrap;
				text-overflow: ellipsis;
				margin-right: 1rem;
				overflow: hidden;
			}
		}
	}
}
.broker-img {
	width: 104px;
}
.slick-arrow-custom {
	position: absolute;
	display: block;
	padding: 0;
	top: 50%;
}
.aff-input {
	font-size: 14px !important;
}
.broker_img {
	position: relative;
	width: 150px;
	height: 150px;
	// border: 1px solid $gray-color;
	margin: 8px;
	border-radius: 5%;
	overflow: hidden;
	> img {
		object-fit: cover;
	}
}
.verification_img {
	position: relative;
	width: 300px;
	height: 300px;
	border: none;
	margin: 8px;

	overflow: hidden;
	> img {
		object-fit: cover;
	}
}
.broker-name {
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	display: flex;
}
.table td,
.table th {
	padding: 0.75rem;
	vertical-align: top;
	border-top: 1px solid #dee2e6;
}
.table-bordered td,
.table-bordered th {
	border: unset;
}

//-----------------------------------------------responsive---------------------------------------------//
//------------------------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------------------------//
//------------------------------------------------------------------------------------------------------//

@media screen and (max-width: 2080px) {
	.each-slide .video-item {
		width: 317px !important;
	}
}
@media screen and (max-width: 1580px) {
	.each-slide .video-item {
		width: 317px !important;
	}
	// .panel-wallet-responsive {
	// 	.panel {
	// 		height: 507.41px;
	// 	}
	// }
}
@media screen and (max-width: 1280px) {
	.each-slide div {
		height: unset !important;
	}
	.content > .row-responsive {
		padding: unset !important;
	}
	.myprofile-responsive {
		margin-right: unset;
	}
}
@media screen and (max-width: 1080px) {
	.each-slide .video-item {
		width: 317px !important;
	}
	.myprofile-responsive {
		margin-right: unset;
	}
}
@media screen and (max-width: 980px) {
	.panel-respon {
		.panel {
			height: 500px;
		}
	}
	.panel-wallet-responsive {
		.panel {
			height: 500px;
		}
	}
}
@media screen and (max-width: 780px) {
	.myprofile-responsive {
		margin: unset;
	}
	.yourbalance-responsive {
		margin: unset;
	}
	.content > .row-responsive {
		margin-left: -15px;
		margin-right: -15px;
		padding: unset;
	}
	.panel-respon {
		height: 600px;
	}
	.dash-board-wallet {
		.btn-border-sky {
			width: 100%;
		}
	}
	.dash-board-wallet {
		display: flex;
		justify-content: center;
		.btn-sky {
			margin: 0 2px;
		}
		.btn-orange {
			margin: 0 2px;
		}
	}

	// .panel-wallet-responsive {
	// 	padding: unset !important;
	// 	.panel {
	// 		// height: 449px;
	// 	}
	// 	h1 {
	// 		font-size: 1rem;
	// 		display: flex;
	// 		justify-content: center;
	// 	}
	// }
	.content {
		.content-responsive {
			height: unset;
		}
	}
	.content {
		.button-responsive {
			padding: unset;
		}
	}

	.wallet-img-responsive {
		height: 300px;
	}
	.wd-bl {
		top: unset;
	}
}

@media screen and (max-width: 580px) {
	.content > .row-responsive {
		margin-left: -30px;
		margin-right: -30px;
	}
	.content.edit_profile {
		> .-profile {
			flex-wrap: wrap;
			justify-content: center;
		}
		.mw-100 {
			min-width: unset;
		}
	}

	.openacc {
		color: red;
		margin: 5px 30px;
		font-size: 14px;
	}
	.invite-detail {
		display: block;
	}
	.wd-bl {
		top: unset;
	}
	.header-panel-table {
		> div {
			font-size: 0.8888rem;
		}
	}
}
@media screen and (max-width: 480px) {
	.dash-board-img {
		width: 66px;
		display: flex;
		justify-content: center;
	}
	.dash-board-profile {
		font-size: 12px;
	}
	.dashboard-container > .header > .title > span {
		// color: #8898aa;
		font-size: 0.8rem;
	}
	.dashboard-container > .header > .title > strong {
		font-size: 0.8rem;
	}
	.dashboard-container > .content {
		padding: 0px 30px;
		margin: 10px;
	}
	.panel-respon {
		.panel {
			height: 450px;
			margin-bottom: 10px;
			margin-top: 5px;
		}
	}
	.panel-respon {
		height: unset;
	}
	.broker-img {
		width: 93.27%;
		display: flex;
		justify-content: center;
	}
	.pagination-responsive {
		.MuiPagination-ul {
			margin: 0;
			display: flex;
			padding: 0;
			flex-wrap: wrap;
			list-style: none;
			align-items: center;
			width: 270px;
		}
	}
}
