@import "./variable.scss";
.courses-img-text {
	padding: 20px;
	width: 162px;
	height: auto;
}
.courses-picture-user {
	width: 80px;
	height: auto;
	margin: 10px;
}
.courses-picture {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
}
.courses-iframe-video {
	width: 100%;
	height: 500px;
}
.courses-box {
	width: 100%;
	height: 100%;
	border: 2px solid;
	border-color: $green-color;
}
.courses-icons {
	background-color: $primary-color;
	color: $white-color;
	margin: 0 10px;
	display: flex;
	align-items: center;
}
.courses-lesson-detail {
	width: 100%;
	height: 500px;
	border-color: $gray-color !important;
	border: 2px solid;
}
.panel-courses {
	height: 360px;
}
