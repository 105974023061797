@import "./variable.scss";
//
.contact {
    // background-image: url("../images/bg-gray.png");

    > .header {
        // background-image: url("../images/dashboard-bg.png");
        background-color: $blue-color;
        height: 5%;
    }
    > .form {
        padding: 0 8%;
        margin-bottom: 50px;
        .left {
            padding-top: 10vh;
            > .title {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1.5rem;
            }
            .form-control {
                height: auto;
                padding: 0.875rem 0.95rem;
                border-radius: 0.55rem;
                border: 1px solid $orange-color;
            }
            textarea {
                min-height: 250px;
            }
        }
        .right {
            display: flex;
            align-items: flex-end;
            > .iphone {
                max-width: 400px;
                height: fit-content;
            }
        }
    }
    .more-detail:hover {
        cursor: pointer;
        transition: 1s;
        transform: rotate(0deg) scale(1.1);
    }
}

.promotion-title-each {
    width: 150px;
    height: 24px;
    margin-top: -30px;
    background-color: $red-color;
    box-shadow: 30px 37px 22px -31px rgba(0, 0, 0, 0.75);
    color: $white-color;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
}
.promotion-title-text {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
@media screen and (max-width: 1080px) {
    .contact > .header {
        margin-bottom: 0;
    }
}
@media screen and (max-width: 992px) {
    .contact {
        min-height: calc(100vh - 90px - 325px);
        > .header {
            display: none;
        }
        > .form {
            .left {
                > .title {
                    text-align: center;
                }
                .btn-orange {
                    margin-left: auto;
                }
            }
            .right {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 580px) {
    .contact > .form .left > .title {
        font-size: 1.6rem;
    }
    .custom-file > .custom-file-label {
        font-size: 0.6rem !important;
    }
}
