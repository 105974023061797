@import "./variable.scss";
.dashboard-aff {
	.-table {
		> .-web {
			@media screen and (max-width: 780px) {
				display: none;
			}
		}
		> .-mobile {
			display: none;
			@media screen and (max-width: 780px) {
				display: block;
			}
		}
	}
}

.aff-custom-tab {
	&.nav-tabs .nav-link:focus,
	&.nav-tabs .nav-link:hover {
		outline: unset;
		border-bottom: 2px solid #00524bc2;
		color: #00524bc2;
	}
	+ .tab-content > .tab-pane {
		padding: 24px 0;
		background-color: #fff;
	}
	a {
		color: #777;
	}
}
// .react-bs-table-container {
// 	.react-bs-table table {
// 		white-space: pre-line;
// 		text-overflow: unset;
// 		overflow-wrap: break-word;
// 	}
// }
.iconbutton {
	color: $white-color;
	background-color: $orange-color;
	border-radius: 50px;
	width: 70px;
	height: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
	&.affiliate {
		background-color: $light-gray;
	}
	&.users {
		background-color: $green-color;
	}
}
.MuiPagination-ul {
	margin: 0;
	display: flex;
	padding: 0;
	flex-wrap: wrap;
	list-style: none;
	align-items: center;
	display: flex;
	justify-content: flex-end;
}
.iconstatus {
	background-color: $light-green;
	border-radius: 50px;
	width: 12px;
	height: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.panel-search > .react-datepicker-wrapper > .react-datepicker__input-container > input {
	border: unset;
	width: 100%;
}
.panel-search > .react-datepicker-wrapper > .react-datepicker__input-container {
	padding: 0 10px;
}
.panel-search > input {
	width: 100%;
}
.aff_verify {
	display: flex;
	justify-content: center;
	align-items: center;
	strong {
		font-size: 2rem;
		font-weight: 500;
		margin-top: 60px;
		color: #0066ff;
	}
}
@media screen and (max-width: 580px) {
	.nav {
		display: block;
		flex-wrap: nowrap;
	}
	.nav-tabs .nav-link {
		border: unset;
	}
	.nav-tabs .nav-link.active {
		color: #495057;
		background-color: #fff;
		border-color: #dee2e6 #dee2e6 #fff;
		border-bottom: 2px solid #00524bc2;
	}
}
