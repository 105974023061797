//
@import "./variable.scss";

// mixin
@mixin awesome_font {
	display: inline-block;
	font-style: normal;
	font-variant: normal;
	text-rendering: auto;
	line-height: 1;
	font-weight: 900;
	font-family: "Font Awesome 5 Free";
	moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

// font-family: 'Prompt', sans-serif;
@import url("https://fonts.googleapis.com/css?family=Prompt:300,400,500&display=swap");
html {
	scroll-behavior: smooth;
}

body {
	// font-size: 20px;
	background-color: $body-background;
	font-size: 18px;
	font-family: "prompt", sans-serif !important;
}

// image
img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	object-position: center;
}
//pagination
.MuiPagination-ul {
	padding: 5px !important;
}
// cursor
.cursor-pointer {
	cursor: pointer;
}

.panel-contractus {
	display: block;
	width: 100%;
	margin: auto;
	margin-bottom: 30px;

	background-color: $panel-background;
}

// panel
.panel {
	display: block;
	width: 100%;
	padding: 25px 30px;
	margin: auto;
	margin-bottom: 30px;
	border-radius: 2px;
	background-color: $panel-background;
	> .line_bottom {
		width: calc(100% + 60px);
		margin-top: 25px;
		margin-left: -30px;
		margin-right: -30px;
	}
	&._radius {
		border-radius: 10px;
	}
	&._shadow {
		box-shadow: 0 0 10px 0 #0000001a;
	}
	&._opacity {
		background-color: hsla(0, 0%, 100%, 0.5);
	}
	&._add_padding {
		padding: 45px 50px;
		> .line_bottom {
			width: calc(100% + 100px);
			margin-top: 45px;
			margin-left: -50px;
			margin-right: -50px;
		}
	}
	&._border {
		border: 1px solid #ccc;
	}

	> .title {
		display: block;
		font-size: 1.334rem;
		font-weight: 600;
		color: $panel-title-color;
		margin-bottom: 0.889rem;

		&._nowrap {
			white-space: nowrap;
		}
		&._center {
			text-align: center;
		}
		&._right {
			text-align: right;
		}

		&._between {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		&._big {
			font-size: 1.8rem;
			font-weight: 700;
		}
	}
	@for $i from 0 through 5 {
		&._mb-#{$i} {
			margin-bottom: calc(10px * #{$i});
		}
	}
}

// button
.btn {
	color: $white-color;
	&-orange {
		background-color: $orange-color;
		border: 1px solid $orange-color;
	}
	&-light-green {
		background-color: $light-green;
	}
	&-yellow {
		background-color: #f0fd0ba3;
		color: #000;
		border: 1px solid $orange-color;
	}
	&-sky {
		background-color: $sky-color;
		border: 1px solid $sky-color;
	}
	&-blue {
		background-color: $blue-color;
		border: 1px solid $blue-color;
	}
	&-gray {
		background-color: $gray-color;
		border: 1px solid $gray-color;
	}
	&-red {
		background-color: $red-color;
		border: 1px solid $red-color;
	}
	&-radius,
	&.radius {
		border-radius: 5rem;
	}

	&-green {
		background-color: $green-color;
		border: 1px solid $green-color;
	}

	&-border {
		&-orange {
			color: $orange-color;
			border: 1px solid $orange-color;
			background-color: $white-color;
			&:hover {
				background-color: $orange-color;
			}
		}
		&-sky {
			color: $sky-color;
			border: 1px solid $sky-color;
			background-color: $white-color;
			&:hover {
				background-color: $sky-color;
			}
		}
		&-blue {
			color: $blue-color;
			border: 1px solid $blue-color;
			background-color: $white-color;
			&:hover {
				background-color: $blue-color;
			}
		}
		&-gray {
			&:hover {
				background-color: $blue-color;
			}
			color: $gray-color;
			border: 1px solid $gray-color;
			background-color: $white-color;
			&:hover {
				background-color: $gray-color;
			}
		}
		&-red {
			color: $red-color;
			border: 1px solid $red-color;
			background-color: $white-color;
			&:hover {
				background-color: $red-color;
			}
		}
		&-green {
			color: $green-color;
			border: 1px solid $green-color;
			&:hover {
				background-color: $green-color;
			}
		}
		&-light-green {
			color: $light-green;
			border: 1px solid $light-green;
			&:hover {
				background-color: $light-green;
			}
		}
		&-black {
			color: $black-color;
			border: 1px solid $black-color;
			&:hover {
				background-color: $black-color;
			}
		}
	}
	&-mw,
	&.mw {
		&-100 {
			min-width: 150px;
		}
		&-150 {
			min-width: 150px;
		}
		&-200 {
			min-width: 200px;
		}
		&-250 {
			min-width: 250px;
		}
		&-300 {
			min-width: 300px;
		}
	}
	&-bigger,
	&.bigger {
		font-size: 1.2rem;
		padding: 0.775rem 0.95rem;
	}
	&:hover {
		color: $white-color;
		filter: grayscale(20%);
	}
}

// text
.text {
	&-red {
		color: $red-color;
	}
	&-white {
		color: $white-color;
	}
	&-orange {
		color: $orange-color;
	}
	&-sky {
		color: $sky-color;
	}
	&-blue {
		color: $blue-color;
	}
	&-gray {
		color: $gray-color;
	}
	&-green {
		color: $green-color;
	}
	&-yellow {
		color: $dark-yellow;
	}
	&-dark-gray {
		color: #8898aa;
	}
	&-light-green {
		color: $light-green;
	}
}

// border
.border {
	&-white {
		border: 1px solid $white-color;
	}
	&-red {
		border: 1px solid $red-color;
	}
	&-orange {
		border: 1px solid $orange-color;
	}
	&-sky {
		border: 1px solid $sky-color;
	}
	&-blue {
		border: 1px solid $blue-color;
	}
	&-gray {
		border: 1px solid $gray-color;
	}
}

.custom-file > .custom-file-label {
	font-size: 1rem;
}

// dashboard
.dashboard-container {
	position: relative;
	background-color: $white-color;
	> .header {
		position: relative;
		width: 100%;
		> .background {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			display: block;
			width: 100%;
			height: 530px;
			z-index: -1;
			> img {
				object-fit: cover;
			}
		}
		> .title {
			padding-top: 150px;
			padding-left: 50px;
			padding-right: 50px;
			> strong {
				font-size: 1.5rem;
				// color: $white-color;
				margin-right: 10px;
			}
			> span {
				// color: $gray-color;
			}
		}
	}
	> .content {
		padding: 20px 50px;
		// margin-left: 250px;
	}
}
.header_content_promotion {
	.header {
		position: relative;
		height: 120px;
		margin-bottom: 85px;
		background-size: cover;
	}
}
.header_content {
	> .header {
		position: relative;
		height: 250px;
		margin-bottom: 85px;
		background-size: cover;
		> .icon {
			position: absolute;
			bottom: -62px;
			left: calc(50% - 62px);
			width: 124px;
			height: 124px;
			padding: 20px;
			border: 2px solid #ffffff;
			border-radius: 50%;
			overflow: hidden;
			background-image: linear-gradient(to right, #27aab9, #2697c4);
			z-index: 1;
			&::after {
				content: "";
				position: absolute;
				top: 12px;
				left: 12px;
				bottom: 12px;
				right: 12px;
				display: block;
				width: 96px;
				height: 96px;
				border-radius: 50%;
				overflow: hidden;
				background-image: linear-gradient(to left, #27aab9, #2697c4);
				z-index: -1;
			}
		}
	}
	> .title {
		font-size: 2rem;
		color: $primary-color;
		text-align: center;
		font-weight: 700;
	}
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
	background-color: #ffffff !important;
	color: #555 !important;
	-webkit-box-shadow: 0 0 0 1000px white inset !important;
	-webkit-text-fill-color: #555555 !important;
}

.react-bs-table-container {
	.react-bs-table-pagination .react-bootstrap-table-page-btns-ul .page-item {
		.page-link {
			color: #000;
			font-weight: 400;
			font-size: 0.875rem;
			border-radius: 16px;
			margin-left: 5px;
			margin-right: 5px;
			border-color: transparent;
			background-color: transparent;
			&:focus {
				outline: unset;
				box-shadow: unset;
			}
			&:hover {
				background-color: #0000000a;
			}
		}
		&.active .page-link {
			background-color: #00000014;
		}
		&[title="next page"] .page-link,
		&[title="previous page"] .page-link {
			display: block;
			width: 33px;
			height: 33px;
			font-size: 0px;
			&::after {
				position: absolute;
				top: calc(50% - 0.4rem);
				right: 0;
				bottom: 0;
				left: 0;
				font-size: 0.875rem;
				color: #000000de;
				text-align: center;
				@include awesome_font();
			}
		}
		&[title="previous page"] .page-link::after {
			content: "\f104";
		}
		&[title="next page"] .page-link::after {
			content: "\f105";
		}
	}
	thead {
		.sort-column {
			.order {
				@include awesome_font();
				&.dropup {
					& > span::after {
						content: "\f0d8";
					}
				}
				.dropdown {
					& > span::after {
						content: "\f0d7";
					}
				}
				.dropup {
					& > span::after {
						content: "\f0d8";
					}
				}
				& > .caret::after {
					content: "\f0d7";
				}
			}
		}
	}
	tr {
		td {
			&:focus {
				outline: unset;
			}
		}
	}
}

.g-verify {
	min-height: 100vh;
	padding-top: 250px;
	text-align: center;
	background-image: url("../images/bg.png");
	> .-box {
		max-width: 500px;
		padding: 10px 30px;
		margin: 0 auto;
		border-radius: 5px;
		border: 1px solid #f24a00;
		background-color: #ffaf8c;
	}
}

.g-resend-email {
	margin-bottom: 1rem;
	padding: 4px 10px;
	border-radius: 5px;
	border: 2px solid #ffe600;
	background-color: #e9ff003b;
	u {
		color: $sky-color;
		cursor: pointer;
	}
}

.g-reject-verify {
	margin-bottom: 1rem;
	padding: 4px 10px;
	border-radius: 5px;
	color: #ff0000;
	border: 2px solid #ffe600;
	background-color: #e9ff003b;
	u {
		color: #ff0000;
		cursor: pointer;
	}
}
// -------- responsive ------------------------------------------------------------------

@media screen and (min-width: 1380px) {
	@for $i from 1 through 12 {
		.col-xxl-#{$i} {
			-ms-flex: 0 0 calc(8.333333% * #{$i});
			flex: 0 0 calc(8.333333% * #{$i});
			max-width: calc(8.333333% * #{$i});
			position: relative;
			width: 100%;
			padding-right: 15px;
			padding-left: 15px;
		}
	}
}
@media screen and (max-width: 1080px) {
	.header_content > .header {
		height: 250px;
	}

	// dashboard
	.dashboard-container {
		position: static;
		> .header {
			position: static;
			> .background {
				height: 100px;
			}
			> .title {
				padding-top: 0px;
				padding-bottom: 0.5rem;
				background-color: #f5f6f7;
				box-shadow: 0 5px 10px #0000001a;
				> strong {
					font-size: 1.2rem;
					color: $primary-color;
					opacity: 0.9;
				}
				> span {
					font-size: 1rem;
				}
			}
		}
	}
}
@media screen and (max-width: 580px) {
	.header_content > .header {
		height: 150px;
	}
}

.app {
	text-align: center;
	background-color: #2a2a2ab5;
	height: 100vh;
   }
 .app .rich-editor {
	padding: 10rem;
   }
 .rich-editor .rdw-editor-main {
	background-color: #fff;
   }
 .rich-editor .DraftEditor-editorContainer {
	padding: 1rem;
   }
 .draft-editor-wrapper {
	border: 1px solid #ccc;
 }